.chat-message {
  background-color: white;
  padding: 24px 12px;
  border-radius: 20px 20px 0px 0px;
  border: 1px solid var(--Greyscale-12, #e8e8e8);
  background: #fff;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.05);
  width: 75%;

  .form-check-input:checked {
    border: 1px solid var(--Main-lightblue, #d2e8fb);
  }

  .form-switch-custom .form-check-input:checked::before {
    right: 0px;
  }

  .form-switch-custom .form-check-input::before {
    top: 0;
    content: "";
    background-image: url("../../../../images/Icon/EyeIcon.svg");
    background-size: contain;
    width: 22px;
    height: 22px;
    border-radius: 99px;
    background-color: var(--Main-midblue, #007bc4);
  }

  .form-switch-custom .form-check-input::after {
    background-color: red;
  }

  input {
    padding-right: 8%;
    border-top-right-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
    border-top-left-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
    border: 1px solid var(--Main-lightblue, #d2e8fb);
  }

  Label {
    color: var(--Greyscale-85, #4d4d4d);
    font-family: Satoshi;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;

    .focus-outer {
      display: flex;
      align-items: center;
      gap: 6px;

      .focus {
        color: var(--Main-midblue, #007bc4);
        font-family: Satoshi;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

  .submit-btn {
    position: absolute;
    display: flex;
    align-items: center;
    right: 10px;
    border-radius: 10px !important;
    margin: 6px;
    padding: 8px;
    z-index: 6;
    cursor: not-allowed;
    background: var(--Greyscale-12, #e8e8e8);

    &.active {
      cursor: pointer;
      background-color: #007bc4;
    }
  }

  .submit-btn img {
    width: 20px;
    height: 20px;
  }
}

.popup .chat-message {
  border-radius: 20px;
  width: 45rem;
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .chat-message {
    padding: 12px;
    width: 70%;
    max-width: 42rem;

    .submit-btn {
      margin: 4px;
      padding: 4px;
    }
    input {
      min-height: 1rem !important;
      padding: 0.4rem;
      padding-right: 8% !important;
    }

    Label {
      font-size: 12px;
    }

    .form-check-input {
      transform: scale(0.9);
    }
  }
}
