.message-list {
  border-radius: 6px;
  border: 1px solid var(--Main-white, #fff);
  display: flex;
  height: 44px;
  padding: 0px 0px 0px 10px;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    border: 1px solid #ebf6ff;
  }

  &.active {
    background: #ebf6ff;
  }

  .text {
    color: var(--Greyscale-85, #4d4d4d);
    font-family: Satoshi;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .delete-icon {
    color: #a12553;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .message-list {
    height: 38px;
    .text {
      font-size: 13px;
    }
  }
}
