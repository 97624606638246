.page-content {
  height: 100vh;
  padding: 0 !important;
  background-color: white;
}

.main-content-inner {
  display: flex;
  height: 100%;
}

.main-content-inner .toggle-icon {
  margin-top: 6rem;
  margin-left: 1rem;
  cursor: pointer;
}

.main-content-inner .toggle-icon .toggle-icon-size {
  font-size: 20px;
}

.navbar-menu .navbar-nav .nav-link {
  padding: 15px;
}

.navbar-nav {
  display: flex;
  align-items: center;
  gap: 15px;
}

.navbar-menu .navbar-nav .nav-link.active {
  border-radius: 20px;
  background-color: white;
}

.navbar-menu {
  border: none !important;
}

.divider {
  width: 44px;
  height: 1px;
  background: #007bc4;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.new-chat-sidebar {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: var(--Main-midblue, #007bc4);
  cursor: pointer;
}

.loading-spinner {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 999;
  pointer-events: stroke;

  .loading-icon {
    display: flex;
    align-items: center;
    margin: auto;
    height: 100vh;
    justify-content: center;
  }
}

::-webkit-scrollbar {
  width: 0;
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .navbar-menu .navbar-nav .nav-link {
    padding: 12px;
  }
  .navbar-menu .navbar-nav {
    gap: 12px;
  }
}
