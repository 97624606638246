.chat-sidebar {
  width: 290px;
  height: 100vh;
  background: white;
  padding: 20px;
  overflow: hidden;
  opacity: 1;
  position: absolute;

  .accordion-body {
    padding: 0px;
  }

  .new-chat {
    background: #d2e8fb;
    display: flex;
    padding: 10px 16px 10px 16px;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 1rem;
    background: linear-gradient(
      to left,
      #d2e8fb 0%,
      #d2e8fb 50%,
      #007bc4 50%,
      #007bc4 100%
    );
    background-size: 200% 100%;
    transition: background-position 0.4s;
    background-position: right;
    cursor: pointer;

    &:hover {
      background-position: left;

      .left {
        .text {
          color: var(--mainwhite, #fff);
        }
      }
    }

    .left,
    .right {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    .right {
      gap: 3px;
    }

    .left .text {
      font-weight: 500;
      color: var(--Main-midblue, #007bc4);
      font-family: Satoshi;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      &:hover {
        color: var(--mainwhite, #fff);
      }
    }
  }

  .history {
    display: grid;
    gap: 12px;

    .search {
      margin-top: 20px;
    }

    .search h4 {
      color: var(--Main-black, #212121);
      font-family: Satoshi;
      font-size: 17px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .search-inner {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      #search-options,
      #search-options:-webkit-autofill,
      #search-options:-webkit-autofill:focus {
        background-color: white !important;
        background-image: url("../../../../images/Icon/SearchIcon.svg") !important;
        background-position: 10px 10px;
        background-repeat: no-repeat;
        padding-left: 30px;
      }
    }

    .accordion-button {
      color: var(--Greyscale-65, #7a7a7a);
      font-family: Satoshi;
      font-size: 13px;
      font-style: normal;
      line-height: normal;
      padding: 10px;
      background-color: white;
      font-weight: 500;
      box-shadow: none !important;
      text-transform: uppercase;
    }
    .accordion-item {
      border: none;
    }

    .heading {
      color: var(--Greyscale-85, #4d4d4d);
      font-family: Satoshi;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0.5rem 0rem;
    }
  }

  .right .action-icon {
    color: #000;
    text-align: center;
    font-family: Satoshi;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 5px 10px;
    border-radius: 10px;
    background: var(--Main-white, #fff);
    box-shadow: 4px 3px 10px 0px rgba(0, 85, 147, 0.1);
  }

  #chat-history {
    height: calc(100vh - 200px);
    overflow: scroll;
  }
}

.chat-sidebar.hide {
  visibility: hidden;
  opacity: 0;
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .chat-sidebar {
    width: 260px;

    #chat-history {
      height: calc(100vh - 155px);
    }

    .form-control {
      padding: 0.3rem;
      font-size: 0.7125rem;
    }

    #search-options {
      background-position: 10px 5px !important;
    }

    .new-chat {
      padding: 8px 12px;
      margin-bottom: 0px;
      margin-right: 0px;
    }

    .history .search h4,
    .history .heading {
      font-size: 13px;
    }
    .new-chat .left .text {
      font-size: 12px;
    }

    .right .action-icon {
      font-size: 11px;
    }
  }
}
