.log-in {
  background-color: #fff;
  align-items: flex-start;
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
}

.log-in .left {
  -webkit-backdrop-filter: blur(20px) brightness(100%);
  align-self: stretch;
  backdrop-filter: blur(20px) brightness(100%);
  background-color: #003f65a6;
  background-image: url(https://c.animaapp.com/6WVrqWtO/img/left-1.png);
  background-position: 50% 50%;
  background-size: cover;
  position: relative;
  width: 50%;
}

.log-in .right {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  position: relative;
}

.log-in .content {
  align-items: flex-start;
  display: inline-flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 60px;
  justify-content: center;
  max-width: 345px;
  position: relative;
}

.log-in .SSO-login-logo {
  height: 30px !important;
  position: relative !important;
  width: 137.73px !important;
}

.log-in .login-form {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  padding: 0px 0px 80px;
  position: relative;
  width: 100%;
}

.log-in .text {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.log-in .text-wrapper-3 {
  align-self: stretch;
  color: rgba(33, 33, 33, 1);
  font-family: "Satoshi", Helvetica;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.log-in .text-wrapper-4 {
  align-self: stretch;
  color: var(--mainblack);
  font-family: "Satoshi", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: normal;
  position: relative;
}

.log-in .form {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  position: relative;
}

.log-in .input-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.log-in .frame-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  justify-content: center;
  position: relative;
}

.log-in .design-component-instance-node {
  background: none;
  border: 1px solid;
  border-color: var(--greyscale-25);
  border-radius: 6px;
  color: var(--greyscale-45);
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  margin-top: -1px;
  padding: 10px 12px;
  position: relative;
  width: 345px;
}

.log-in .buttons {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.log-in .button-instance {
  align-self: stretch !important;
  display: flex !important;
  width: 100% !important;
}

.log-in .SSO-login-instance {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.log-in .don-t-have-an {
  align-self: stretch;
  color: transparent;
  font-family: "Satoshi-Medium", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.log-in .span {
  color: #212121;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.log-in .text-wrapper-5 {
  color: #007bc4;
  font-family: "Satoshi-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
}

.log-in .checkbox-instance {
  height: 16px !important;
  position: relative !important;
  width: 16px !important;
}
.checkbox-text {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 5px 0 12px;
}
.checkbox-span {
  color: var(--Main-black, #212121);
  font-family: Satoshi;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.checkbox {
  border-radius: 4px;
  border: 1px solid var(--Greyscale-25, #d3d3d3);
}
.SSO-login {
  border: 1px solid;
  border-color: var(--greyscale-25);
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 346px;
  padding: 10px 15px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.SSO-login .SSO-icon {
  height: 24px;
  position: relative;
  width: 24px;
}

.SSO-login .label {
  color: var(--mainblack);
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  position: relative;
  width: fit-content;
}

.SSO-login:hover {
  background-color: var(--greyscale-12);
}
.lottie-container {
  justify-content: center;
  margin-top: 15rem;
  width: 100%;
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .log-in .design-component-instance-node {
    padding: 0.5rem 0.9rem;
    margin-top: 0px;
  }
}
