.padding-main {
  width: 100%;
  padding-left: 1.9rem;
  padding-right: 1.9rem;
}

.backgroung-main {
  background-image: url(../../../../../assets/images/DashboardBg.jpeg);
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
  height: 100vh;
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  background-repeat: no-repeat;
  background-size: cover;
}

.frame3 {
  display: flex;
  align-items: center;
  background-color: #ffffff66;
  border: 1px solid transparent;
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
  margin-left: 1vh;
  backdrop-filter: blur(10px);
  justify-content: center;

  .div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ebf6ff;
      border-radius: 8rem;
      padding: 4px;
      width: 2.5rem;

      .icon {
        height: 30px !important;
        width: 3rem !important;
        color: #007bc4;
      }
    }

    .currently-there-are,
    .things-seem-a-bit {
      width: 27rem;
      color: var(--mainblack);
      font-family: var(--subheading-2-font-family);
      font-size: var(--subheading-2-font-size);
      font-style: var(--subheading-2-font-style);
      font-weight: var(--subheading-2-font-weight);
      letter-spacing: var(--subheading-2-letter-spacing);
      line-height: var(--subheading-2-line-height);
      text-align: center;
    }
  }
}

.blur-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(25px);
}

.frame {
  align-items: center;
  background-color: rgba(10, 95, 157, 0.4);
  border: 1px solid;
  border-color: transparent;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  height: 70px;

  .text-wrapper-3 {
    color: var(--mainwhite);
    font-family: "Satoshi-Bold", Helvetica;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    padding-left: 12px;
    width: 36.75rem;
  }

  .button-instance {
    display: inline-flex !important;
    flex: 0 0 auto !important;
    gap: 12px !important;
    justify-content: unset !important;
    overflow: unset !important;
    width: unset !important;
  }
}

.frame-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 28.5rem;
}

.frame2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 26rem;

  .KPI-cards {
    -webkit-backdrop-filter: blur(50px) brightness(100%);
    align-items: flex-start;
    align-self: stretch;
    backdrop-filter: blur(50px) brightness(100%);
    background-color: #0a5f9d66;
    border-image: linear-gradient(
        to bottom,
        rgb(255, 255, 255),
        rgb(210, 232, 251) 33%,
        rgb(0, 123, 196) 74.5%,
        rgb(255, 255, 255) 93%
      )
      1;
    border-radius: 20px;
    flex-grow: 1;
    overflow: hidden;
    position: relative;
    width: 100%;
    padding: 14px;

    .div-2 {
      align-items: flex-start;
      align-self: stretch;
      display: flex;
      flex: 1;
      flex-grow: 1;
      gap: 36px;
      position: relative;
      width: 100%;
      max-height: 22vh;
    }

    .div-3 {
      align-items: flex-start;
      align-self: stretch;
      display: flex;
      flex: 1;
      flex-direction: column;
      flex-grow: 1;
      gap: 12px;
      justify-content: center;
      padding: 4vh;
      position: relative;
    }

    .start-your-first-wrapper {
      align-items: flex-start;
      align-self: stretch;
      display: flex;
      flex: 0 0 auto;
      flex-direction: column;
      gap: 6px;
      padding-right: 2vh;
      position: relative;
      width: 100%;
    }

    .start-your-first {
      color: var(--mainwhite);
      font-family: "Satoshi-Medium", Helvetica;
      font-size: 34px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 34px;
      position: relative;
    }

    .button-instance {
      align-self: stretch !important;
      width: 100% !important;
    }

    .icon-small-25 {
      height: 22px !important;
      position: relative !important;
      width: 1.375rem !important;
    }

    .design-component-instance-node {
      margin-left: unset !important;
    }

    .div-4 {
      align-items: flex-start;
      align-self: stretch;
      display: flex;
      flex: 1;
      flex-direction: column;
      flex-grow: 1;
      justify-content: space-between;
      position: relative;
      height: 22vh;
    }

    .div-wrapper {
      align-items: flex-start;
      align-self: stretch;
      display: flex;
      flex-direction: column;
      gap: 6px;
      height: 19px;
      justify-content: space-around;
      position: relative;
      width: 100%;
    }

    .text-wrapper-3 {
      color: var(--mainwhite);
      font-family: "Satoshi-Bold", Helvetica;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0;
      line-height: normal;
      margin-bottom: -0.5px;
      margin-top: -2.5px;
      position: relative;
      width: 17.625rem;
      padding-left: 2vh;
    }

    .div-5 {
      align-items: flex-start;
      display: inline-flex;
      flex: 0 0 auto;
      flex-direction: column;
      gap: 6px;
      position: relative;
      margin-top: 10vh;
      padding-left: 2vh;
    }

    .text-wrapper-4 {
      color: var(--mainwhite);
      font-family: "Satoshi-Medium", Helvetica;
      font-size: 34px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: normal;
      margin-top: -1px;
      position: relative;
      width: fit-content;
    }

    .div-wrapper-2 {
      align-items: flex-start;
      display: inline-flex;
      flex: 0 0 auto;
      gap: 6px;
      position: relative;
    }

    .text-wrapper-5 {
      color: var(--mainwhite);
      font-family: "Satoshi-Medium", Helvetica;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: normal;
      margin-top: -1px;
      position: relative;
      width: fit-content;
    }

    .img {
      align-self: stretch;
      flex: 0 0 auto;
      position: relative;
    }

    .text-wrapper-6 {
      color: var(--mainwhite);
      font-family: "Satoshi-Bold", Helvetica;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0;
      line-height: normal;
      margin-bottom: -0.5px;
      margin-top: -2.5px;
      position: relative;
      width: fit-content;
    }
  }
}

//   chat button
.new-chat {
  background: linear-gradient(
    to left,
    #d2e8fb 0%,
    #d2e8fb 50%,
    #007bc4 50%,
    #007bc4 100%
  );
  background-size: 200% 100%;
  transition: background-position 0.4s;
  display: flex;
  padding: 10px 16px;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  margin-right: 12px;
  cursor: pointer;
  background-position: right;
  /* Start the gradient from the left */
}

.new-chat:hover {
  background-position: left;
  /* Shift the gradient to the right on hover */
}

.right {
  display: flex;
  align-items: center;
  gap: 12px;
}

.right {
  gap: 3px;
}

.left {
  color: var(--Main-midblue, #007bc4);
  font-family: Satoshi;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  gap: 10px;
  display: flex;
}

.new-chat .left .text {
  font-weight: 500;
  color: var(--Main-midblue, #007bc4);
  font-family: Satoshi;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.new-chat:hover .left .text {
  color: var(--mainwhite, #fff);
  /* Change text color on hover */
}

.new-chat-icon {
  width: 1.375rem;
  height: 1.375rem;
  border-radius: 6px;
  background: var(--Main-midblue, #007bc4);
  cursor: pointer;
}

.right .action-icon {
  color: #000;
  text-align: center;
  font-family: Satoshi;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 5px 10px;
  border-radius: 10px;
  background: var(--Main-white, #fff);
  box-shadow: 4px 3px 10px 0px rgba(0, 85, 147, 0.1);
}
@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .frame .text-wrapper-3 {
    font-size: 20px;
    width: 30rem;
  }

  .frame {
    height: 50px;

    .new-chat {
      padding: 6px 10px;
    }

    .right .action-icon {
      font-size: 11px;
    }
  }

  .frame3 {
    margin-left: 1vh;
  }

  .frame-container {
    width: 25rem;
  }

  .frame2 {
    width: 22rem;
    gap: 6px;
  }
  .frame2 .KPI-cards .start-your-first {
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    display: contents;
  }
  .start-your-first-wrapper {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 6px;
    padding-right: 2vh;
    position: relative;
  }
}
