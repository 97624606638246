.prompt-card {
  padding: 16px;
  display: grid;
  gap: 4px;
  max-width: 14rem;
  height: 8rem;
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid var(--Greyscale-12, #e8e8e8);
  background: var(--Main-white, #fff);
}

.prompt-card .header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0%;
}

.prompt-card .header .icon-size {
  font-size: large;
  opacity: 0.6;
}

.prompt-card .header .title {
  color: var(--Greyscale-black, #212121);
  font-family: Satoshi;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.5em;
}

.prompt-card .description {
  color: var(--Greyscale-65, #7a7a7a);
  font-family: Satoshi;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  max-height: 3em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.5em;
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .prompt-card {
    max-width: 10rem;
    height: 7rem;
    padding: 10px;

    .header .title {
      font-size: 12px;
    }
    .description {
      font-size: 11px;
    }
  }
}
