.chat-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2f2e2e2b;
  backdrop-filter: blur(5px);
  z-index: 9999;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .popup {
    max-width: 56rem;
    border-radius: 8px;
    padding: 20px;
  }
}
