.message-card {
  border-radius: 20px;
  border: 1px solid var(--Greyscale-12, #e8e8e8);
  background: var(--Main-white, #fff);
  padding: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;

  .content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    width: 100%;

    .title {
      color: var(--Greyscale-85, #4d4d4d);
      font-family: Satoshi;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .message {
      color: var(--Main-black, #212121);
      font-family: Satoshi;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  .profile {
    .user-profile {
      display: flex;
      width: 16px;
      height: 16px;
      max-width: 100%;
      max-height: 100%;
      border-radius: 50%;
      background: grey;
      font-size: 11px;
      color: #fff;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
    }
  }
}

.message-card.response {
  border-radius: 20px;
  border: 1px solid var(--Greyscale-12, #e8e8e8);
  background: var(--Main-white, #fff);
  padding: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 4px;

  .head {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }

  .message-card-footer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    .icons {
      display: flex;
      gap: 10px;

      img {
        cursor: pointer;
        height: 1rem;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    width: 100%;

    .top {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
      width: 100%;
    }

    .title {
      color: var(--Greyscale-85, #4d4d4d);
      font-family: Satoshi;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .message {
      color: var(--Main-black, #212121);
      font-family: Satoshi;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .message-card,
  .message-card.response {
    padding: 16px;

    .content {
      .title {
        font-size: 12px;
      }
      .message {
        font-size: 13px;
      }
    }
  }
}
