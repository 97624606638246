:root {
    --greyscale-12: rgba(232, 232, 232, 1);
    --greyscale-25: rgba(211, 211, 211, 1);
    --greyscale-45: rgba(166, 166, 166, 1);
    --greyscale-65: rgba(122, 122, 122, 1);
    --main-skyblue: rgba(134, 194, 234, 1);
    --mainblack: rgba(33, 33, 33, 1);
    --maindarkblue: rgba(0, 85, 147, 1);
    --mainlightblue: rgba(210, 232, 251, 1);
    --mainmidblue: rgba(0, 123, 196, 1);
    --mainwhite: rgba(255, 255, 255, 1);
    --secondredmain: rgba(161, 37, 83, 1);
    --subheading-2-font-family: "Satoshi", Helvetica;
    --subheading-2-font-size: 17px;
    --subheading-2-font-style: normal;
    --subheading-2-font-weight: 500;
    --subheading-2-letter-spacing: 0px;
    --subheading-2-line-height: normal;
    --text-font-family: "Satoshi", Helvetica;
    --text-font-size: 15px;
    --text-font-style: normal;
    --text-font-weight: 500;
    --text-letter-spacing: 0px;
    --text-line-height: normal;
    --text-small-font-family: "Satoshi", Helvetica;
    --text-small-font-size: 13px;
    --text-small-font-style: normal;
    --text-small-font-weight: 500;
    --text-small-letter-spacing: 0px;
    --text-small-line-height: normal;
    --title-1-font-family: "Satoshi", Helvetica;
    --title-1-font-size: 32px;
    --title-1-font-style: normal;
    --title-1-font-weight: 700;
    --title-1-letter-spacing: 0px;
    --title-1-line-height: normal;
    --variable-collection-second-yellow-dark: rgba(212, 161, 57, 1);
  }
  