.chat {
  height: 100vh;
  display: flex;
  align-items: center;
  background-image: url("../../../images/chatBg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .toggle-icon {
    position: absolute;
    top: 12%;
    left: 4.5rem;

    .toggle-icon-size {
      cursor: pointer;
      padding: 10px;
      border-radius: 12px;
      background: var(--Main-white, #fff);
    }
  }

  .toggle-icon.right {
    left: 21rem;
  }
}

.chat-container {
  padding-left: 1.5rem;
  padding-right: 0px;
}

.chat-container.show {
  padding-left: 0px;
}

.custom-filter-icon {
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
}

.custom-filter-icon,
.custom-filter-icon :hover {
  border: 1px solid var(--Greyscale-12, #e8e8e8) !important;
}

// Chat screen
.chat-section {
  width: -webkit-fill-available;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: auto;

  .message-card-outer {
    padding: 40px;
    width: 75%;
    height: calc(100vh - 100px);
    overflow: auto;
    display: flex;
    gap: 6px;
    flex-direction: column;
  }
}

.chat-section.left {
  margin-left: 18rem;
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .chat .toggle-icon.right {
    left: 19rem;
  }
  .chat-section .message-card-outer {
    width: 70%;
    max-width: 42rem;
  }
  .custom-filter-icon {
    padding: 4px 8px;

    img {
      width: 14px;
      height: 14px;
    }
  }
  .dropdown-item {
    font-size: 11px;
  }
}
