.prompt {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: auto;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 7%;

    .title {
      color: var(--Main-white, #fff);
      text-align: center;
      font-family: Satoshi;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .subtitle {
      color: var(--Main-white, #fff);
      text-align: center;
      font-family: Satoshi;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .cards-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  .chat-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .start-chat {
    background-color: #d2e8fb;
    padding: 20px;
    width: max-content;
    align-items: center;
    display: flex;
    border-radius: 10px;
    color: #007bc4;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    gap: 20px;

    .right {
      display: flex;
      gap: 10px;
    }
  }

  .start-chat .action-icon {
    background-color: white;
    padding: 5px 10px;
    border-radius: 10px;
    height: 33px;
  }
}

.prompt.left {
  margin-left: 18rem;
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .prompt {
    .header {
      .title {
        font-size: 22px;
      }
      .subtitle {
        font-size: 14px;
      }
    }
  }
}
