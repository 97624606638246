.profile {
  display: flex;
  gap: 10px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.profile .user-profile-image {
  display: flex;
  width: 40px;
  height: 40px;
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%;
  background: grey;
  font-size: 14px;
  color: #fff;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.profile .logout {
  padding: 8px;
  color: #a12553;
  cursor: pointer;
  border-radius: 10px;
  background: var(--Main-white, #fff);
  font-family: Satoshi;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .profile {
    padding: 0px 10px;

    .logout {
      font-size: 12px;
    }
  }
}
